var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('b-card',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.datas,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(data){return [_c('span',{staticClass:"text-md text-primary"},[_vm._v(" "+_vm._s((data.index +1)))])]}},{key:"cell(ministry_name)",fn:function(ref){
var item = ref.item;
return [(item.active_history.management)?_c('span',[_vm._v(" "+_vm._s(item.active_history.management_type.name)+" "),_c('br'),_vm._v(" "+_vm._s(item.active_history.management.name)+", "),_c('br'),(item.active_history.management.user)?_c('span',[_c('strong',[_vm._v(" "+_vm._s(item.active_history.management.user.lastname)+" "+_vm._s(item.active_history.management.user.firstname)+" ")])]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(item.active_history.ministry_name)+" ")])]}},{key:"cell(deadline)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-content-center mt-1"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.active_history.deadline))+" ")]),_c('span',{staticClass:"ml-1"},[_c('b-icon',{attrs:{"icon":"calendar2-week"}})],1)])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('span',{class:("text-" + (item.active_history.status.color))},[_vm._v(" "+_vm._s(item.active_history.status.name)+" ")])]}},{key:"cell(author)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.active_history.author.firstname)+" "+_vm._s(item.active_history.author.lastname)+" ")]}},{key:"cell(updated_date)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-flex justify-content-center mt-1"},[_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.active_history.updated_at))+" ")]),_c('span',{staticClass:"ml-1"},[_c('b-icon',{attrs:{"icon":"calendar2-week"}})],1)])]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.handlePageChange(data.item)}}},[_c('b-icon',{attrs:{"icon":"eye"}})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }