<template>
    <div class="w-100">
      <b-card>
        <b-table
            striped
            hover
            :items="datas"
            :fields="fields"
            responsive
        >
          <template #cell(id)="data">
            <span class="text-md text-primary"> {{ (data.index +1) }}</span>
          </template>
          <template #cell(ministry_name)="{item}">
      <span v-if="item.active_history.management">
        {{item.active_history.management_type.name}} <br> {{item.active_history.management.name}}, <br>
        <span v-if="item.active_history.management.user">
          <strong>
            {{item.active_history.management.user.lastname}} {{item.active_history.management.user.firstname}}
          </strong>
        </span>
      </span>
            <span v-else>
        {{item.active_history.ministry_name}}
      </span>
          </template>
          <template #cell(deadline)="{item}">
            <span class="d-flex justify-content-center mt-1">
                <span>
                  {{ item.active_history.deadline | dateFormat }}
                </span>
                <span class="ml-1"><b-icon icon="calendar2-week"></b-icon></span>
              </span>
          </template>
          <template #cell(status)="{item}">
            <span :class="`text-${item.active_history.status.color}`">
              {{item.active_history.status.name}}
            </span>
          </template>
          <template #cell(author)="{item}">
            {{item.active_history.author.firstname}} {{item.active_history.author.lastname}}
          </template>
          <template #cell(updated_date)="{item}">
            <span class="d-flex justify-content-center mt-1">
                <span>
                  {{ item.active_history.updated_at | dateFormat }}
                </span>
                <span class="ml-1"><b-icon icon="calendar2-week"></b-icon></span>
              </span>
          </template>
          <template #cell(action)="data">
            <div class="d-flex">
              <b-button size="sm" variant="primary" @click="handlePageChange(data.item)" class="mr-1">
                <b-icon icon="eye"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
    </div>
</template>

<script>
import { deleteParagraph, getParagraphs } from '@/api/documents'
import { listTemplate } from '@/utils/mixins/listTemplate'

const actions = {
  get: getParagraphs,
  delete: deleteParagraph,
}

export default {
  name: 'DocumentParagraphByYear',
  mixins: [
    listTemplate,
  ],
  data() {
    return {
      actions,
      filters: {
        document_id: this.$route.params.id,
      },
      fields: [
        {
          key: 'id',
          label: this.$t('ID'),
        },
        {
          key: 'paragraph',
          label: this.$t('Paragraph'),
        },
        {
          key: 'ministry_name',
          label: this.$t('Ministry_name'),
        },
        {
          key: 'deadline',
          label: this.$t('Deadline'),
        },
        {
          key: 'status',
          label: this.$t('Status_id'),
        },
        {
          key: 'author',
          label: this.$t('Author'),
        },
        {
          key: 'updated_date',
          label: this.$t('Updated_date'),
        },
        {
          key: 'action',
          label: this.$t('Action'),
        },
      ],
    }
  },
  methods: {
    handlePageChange(data) {
      this.$router.push({name: 'paragraphHistory',
        params: { id: this.$route.params.id, paragraphId: data.paragraph_id ? data.paragraph_id : data.id },
      })
    }
  }
}
</script>

<style scoped>

</style>
